import { FadeIn, SimpleError } from '@dreamstack/feature-components'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { AllBlocks } from '../blocks/AllBlocks'
import type { QueryAllBlocksRecursivelyResult } from './QueryAllBlocksRecursivelyResult'

export const ContentfulBlock: FunctionComponent<React.PropsWithChildren<{
  block: QueryAllBlocksRecursivelyResult
  customProps?: any
}>> = ({ block, customProps }) => {
  const __typename = block?.__typename ?? 'no-typename'
  const BlockComponent = AllBlocks[__typename]

  if (!BlockComponent) {
    const errorMsg = `Unknown BlockType "${__typename}"`
    console.error(errorMsg)
    return <SimpleError msg={errorMsg} json={block} />
  }
  return (
    <FadeIn>
      <BlockComponent block={block} customProps={customProps} />
    </FadeIn>
  )
}
