import { BASE_URL } from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import { OrganizationJsonLd } from 'next-seo'
import type { FunctionComponent } from 'react'

export const OrganizationRichMarkup: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const t = useTranslation()

  return (
    <>
      <OrganizationJsonLd
        type="RealEstateAgent"
        id={BASE_URL}
        url={BASE_URL}
        telephone="+49308871810"
        name="Accentro GmbH"
        image="https://accentro.de/static/images/accentro-reception-small.jpeg"
        logo="https://accentro.de/static/images/accentro-logo-black.png"
        sameAs={[
          'https://www.xing.com/pages/accentrogmbh',
          'https://www.youtube.com/channel/UCQQCpohq8Q2m9mMJae2e-KA',
          'https://www.instagram.com/accentro.realestate/',
          'https://de.linkedin.com/company/accentrorealestate/',
          'https://www.facebook.com/ACCENTRO-Immobilien-188970351240067/',
        ]}
        description={t('accentro:richMarkup.organization.description')}
        address={{
          streetAddress: 'Kantstraße 44/45',
          addressLocality: 'Berlin',
          addressCountry: 'DE',
          postalCode: '10625',
          email: 'mailto:mail@accentro.de',
        }}
      />
    </>
  )
}
