import type { NextSeoProps } from 'next-seo';
import { NextSeo } from 'next-seo'
import type { OpenGraphMedia } from 'next-seo/lib/types'
import type { FunctionComponent } from 'react'
import { BreadcrumbRichMarkup } from './BreadcrumbRichMarkup'
import { OrganizationRichMarkup } from './OrganizationRichMarkup'
import { useCanonicalUrl } from './useCanonicalUrl'
import { useLanguageAlternates } from './useLanguageAlternates'

export const DreamstackSeo: FunctionComponent<React.PropsWithChildren<NextSeoProps & { image?: OpenGraphMedia }>> = ({ image, ...props }) => {
  const languageAlternates = useLanguageAlternates()
  const images = image?.url ? [image] : []
  const canonicalUrl = useCanonicalUrl() ?? undefined
  return (
    <>
      <NextSeo
        {...props}
        openGraph={{ images }}
        languageAlternates={languageAlternates}
        canonical={canonicalUrl}
      />
      <BreadcrumbRichMarkup />
      <OrganizationRichMarkup />
    </>
  )
}
