import { BASE_URL } from '@dreamstack/feature-components'
import {
  defaultLocale,
  getLocalePrefix,
  useLocale,
  useOtherLocale,
  useOtherLocaleUrl,
} from '@dreamstack/i18n'
import filter from 'lodash/filter'
import find from 'lodash/find'
import join from 'lodash/join'
import map from 'lodash/map'
import trim from 'lodash/trim'
import { useMemo } from 'react'
import { useCanonicalUrl } from './useCanonicalUrl'

// FROM: next-seo internals
interface LanguageAlternate {
  hrefLang: string
  href: string
}

export const useLanguageAlternates = (): LanguageAlternate[] => {
  // OTHER
  const otherLocale = useOtherLocale()
  const slugInOtherLocale = useOtherLocaleUrl()
  let alternates = useMemo(() => {
    if (!slugInOtherLocale) return []
    if (!otherLocale) return []
    const prefix = getLocalePrefix(otherLocale)
    let urlParts = [BASE_URL, prefix, slugInOtherLocale]
    urlParts = map(urlParts, (part) => trim(part, '/'))
    urlParts = filter(urlParts, (part) => part) as any
    const href = join(urlParts, '/')

    const alternate: LanguageAlternate = {
      hrefLang: otherLocale,
      href,
    }

    return [alternate]
  }, [otherLocale, slugInOtherLocale])

  // CURRENT
  const canonicalUrl = useCanonicalUrl()
  const locale = useLocale()
  if (!canonicalUrl) return []
  alternates = [...alternates, { href: canonicalUrl, hrefLang: locale }]
  let defaultAlternate = find(
    alternates,
    ({ hrefLang }) => hrefLang === defaultLocale
  )

  // DEFAULT
  if (defaultAlternate) {
    defaultAlternate = { ...defaultAlternate, hrefLang: 'x-default' }
    alternates = [...alternates, defaultAlternate]
  }

  return alternates
}
