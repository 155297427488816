import { BASE_URL, PROJECT_ID } from '@dreamstack/feature-components'
import { getLocalePrefix, useLocale } from '@dreamstack/i18n'
import filter from 'lodash/filter'
import first from 'lodash/first'
import includes from 'lodash/includes'
import join from 'lodash/join'
import map from 'lodash/map'
import replace from 'lodash/replace'
import split from 'lodash/split'
import trim from 'lodash/trim'
import { useRouter } from 'next/router'
import { parse } from 'url'

type Paths = {
  [project: string]: { [basePath: string]: { [locale: string]: string } }
}

const rewrites: Paths = {
  'accentro-web': {
    news: {
      de: `${process.env.NEXT_PUBLIC_NEWS_BASE_URL_DE}`,
      en: `${process.env.NEXT_PUBLIC_NEWS_BASE_URL_EN}`,
    },
    properties: {
      de: 'immobilien',
      en: 'real-estate',
    },
    projects: {
      de: 'projekte',
    },
    my: {
      de: 'mein-accentro', // MyAccentroBaseUrls.de,
      en: 'my-accentro', //MyAccentroBaseUrls.en,
    },
  },
}

export const useCanonicalUrl = () => {
  const router = useRouter()
  const path = router.asPath
  const locale = useLocale()

  // replace path with value specified in next.config.js rewrites
  const splitPath = split(path, '/')
  const pathBase =
    splitPath?.length && splitPath.length > 1 ? splitPath[1] : 'notfound'
  const pathWithRewrite = rewrites[PROJECT_ID]?.[pathBase]?.[locale]
  const pathReplacedWithRewrite = pathWithRewrite
    ? replace(path, pathBase, pathWithRewrite)
    : path

  const prefix = getLocalePrefix(locale)

  let urlParts = [BASE_URL, prefix, pathReplacedWithRewrite]
  urlParts = map(urlParts, (part) => trim(part, '/'))
  urlParts = filter(urlParts, (part) => part) as any
  let href = join(urlParts, '/')

  // Only keep search params that are not utm stuff
  const parsed = parse(href)
  const searchParams = new URLSearchParams(parsed.query ?? '')
  const utmParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'null',
  ]
  utmParams.forEach((param) => searchParams.delete(param))
  href = first(split(href, '?')) ?? ''

  if (includes(href, '[')) {
    return null
  }

  href = `${href}?${searchParams.toString()}`
  href = trim(href, '?')

  return href
}
