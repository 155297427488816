import { BASE_URL } from '@dreamstack/feature-components'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { BreadcrumbJsonLd } from 'next-seo'
import { useRouter } from 'next/router'
import type { FunctionComponent} from 'react';
import { useMemo } from 'react'

export const BreadcrumbRichMarkup: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const router = useRouter()

  const breadcrumbs = useMemo(() => {
    let itemUrl = BASE_URL
    const breadcrumbTitles = router.asPath.split('/')
    const itemListElements = map(
      filter(breadcrumbTitles, (b) => !!b),
      (urlSection, idx) => {
        itemUrl += '/' + urlSection
        return {
          position: idx + 1,
          name: urlSection[0]?.toUpperCase() + urlSection?.substring(1),
          item: itemUrl,
        }
      }
    )

    return itemListElements
  }, [router.asPath])

  if (!breadcrumbs.length) return null

  return (
    <>
      <BreadcrumbJsonLd itemListElements={breadcrumbs} />
    </>
  )
}
