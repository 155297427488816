import dynamic from 'next/dynamic'
import type { ComponentType } from 'react'

export const AllBlocks: Record<
  string,
  ComponentType<React.PropsWithChildren<{ block: any; customProps?: any }>>
> = {
  // BlockRichText: RichTextBlock,
  BlockRichText: dynamic(() =>
    import('./RichTextBlock').then((m) => m.RichTextBlock)
  ),
  // BlockCallToActionButton: CallToActionButtonBlock,
  BlockCallToActionButton: dynamic(() =>
    import('./CallToActionButtonBlock').then((m) => m.CallToActionButtonBlock)
  ),
  // BlockTwoColumn: TwoColumnLayout,
  BlockTwoColumn: dynamic(() =>
    import('./TwoColumnLayout').then((m) => m.TwoColumnLayout)
  ),
  // BlockOneColumn: OneColumnLayout,
  BlockOneColumn: dynamic(() =>
    import('./OneColumnLayout').then((m) => m.OneColumnLayout)
  ),
  // BlockThreeColumn: ThreeColumnLayout,
  BlockThreeColumn: dynamic(() =>
    import('./ThreeColumnLayout').then((m) => m.ThreeColumnLayout)
  ),
  // BlockAccordion: Accordion,
  BlockAccordion: dynamic(() =>
    import('../components/Accordion').then((m) => m.Accordion)
  ),
  // BlockAccordionEntry: AccordionEntry,
  BlockAccordionEntry: dynamic(() =>
    import('../components/AccordionEntry').then((m) => m.AccordionEntry)
  ),
  // BlockCustom: CustomBlock,
  BlockCustom: dynamic(() =>
    import('./CustomBlocks/CustomBlock').then((m) => m.CustomBlock)
  ),
  // BlockPersonAccordion: PersonAccordion,
  BlockPersonAccordion: dynamic(() =>
    import('./PersonAccordion').then((m) => m.PersonAccordion)
  ),
  // BlockImageGallery: BlockImageGallery,
  BlockImageGallery: dynamic(() =>
    import('./BlockImageGallery').then((m) => m.BlockImageGallery)
  ),
  // PersonAccordionEntry: PersonAccordionEntry,
  PersonAccordionEntry: dynamic(() =>
    import('./PersonAccordionEntry').then((m) => m.PersonAccordionEntry)
  ),
  // BarChartWithBackground: BarChartWithBackgroundBlock,
  BarChartWithBackground: dynamic(() =>
    import('./BarChartWithBackground').then(
      (m) => m.BarChartWithBackgroundBlock
    )
  ),
  // BlockRibbon: BlockRibbon,
  BlockRibbon: dynamic(() =>
    import('./BlockRibbon').then((m) => m.BlockRibbon)
  ),
  // BlockTable: Table,
  BlockTable: dynamic(() => import('./Table/Table').then((m) => m.Table)),
  // LatestDownload: LatestDownload,
  LatestDownload: dynamic(() =>
    import('./LatestDownload').then((m) => m.LatestDownload)
  ),
  // NavigationEntry: NavigationEntry,
  NavigationEntry: dynamic(() =>
    import('./NavigationEntry').then((m) => m.NavigationEntry)
  ),
  // BlockStockPrice: StockPriceSection,
  BlockStockPrice: dynamic(() =>
    import('./StockPriceSection').then((m) => m.StockPriceSection)
  ),
  // BlockBigTitleNavigation: BigTitleNavigation,
  BlockBigTitleNavigation: dynamic(() =>
    import('./BigTitleNavigation').then((m) => m.BigTitleNavigation)
  ),
  // BlockAnalystRating: AnalystRating,
  BlockAnalystRating: dynamic(() =>
    import('./AnalystRatings/AnalystRating').then((m) => m.AnalystRating)
  ),
  // BlockFullsizeImage: FullsizeImage,
  BlockFullsizeImage: dynamic(() =>
    import('./FullsizeImage').then((m) => m.FullsizeImage)
  ),
  // BlockPartnerGrid: PartnerGrid,
  BlockPartnerGrid: dynamic(() =>
    import('./PartnerGrid').then((m) => m.PartnerGrid)
  ),
  // BlockHighlightRealEstate: BlockHighlightRealEstate,
  BlockHighlightRealEstate: dynamic(() =>
    import('./BlockHighlightRealEstate').then((m) => m.BlockHighlightRealEstate)
  ),
  // BlockVideo: Video,
  BlockVideo: dynamic(() => import('./Video').then((m) => m.Video)),
  // BlockLinkSection: LinkSection,
  BlockLinkSection: dynamic(() =>
    import('./LinkSection').then((m) => m.LinkSection)
  ),
  // BlockSubNavigation: SubNavigation,
  BlockSubNavigation: dynamic(() =>
    import('./SubNavigation').then((m) => m.SubNavigation)
  ),
  // BlockTextCloud: BlockTextCloud,
  BlockTextCloud: dynamic(() =>
    import('./BlockTextCloud').then((m) => m.BlockTextCloud)
  ),
  // BlockSpeechBubble: BlockSpeechBubble,
  BlockSpeechBubble: dynamic(() =>
    import('./SpeechBubble').then((m) => m.BlockSpeechBubble)
  ),
  // BlockSlider: BlockSlider,
  BlockSlider: dynamic(() =>
    import('./BlockSlider').then((m) => m.BlockSlider)
  ),
  // BlockSliderEntry: BlockSliderEntry,
  BlockSliderEntry: dynamic(() =>
    import('./BlockSliderEntry').then((m) => m.BlockSliderEntry)
  ),
  BlockHiddenWithConsent: dynamic(() =>
    import('./HiddenWithConsent').then((m) => m.HiddenWithConsent)
  ),
}
