import map from 'lodash/map'
import type { FunctionComponent } from 'react'
import { ContentfulBlock } from './ContentfulBlock'
import type { QueryAllBlocksRecursivelyResult } from './QueryAllBlocksRecursivelyResult'

export const ContentfulBlocks: FunctionComponent<React.PropsWithChildren<{
  blocks?: QueryAllBlocksRecursivelyResult[]
  customProps?: any
}>> = ({ blocks = [], customProps }) => {
  return (
    <>
      {map(blocks, (block, idx) => (
        <ContentfulBlock
          key={`${block?.sys?.id}-${idx}`}
          block={block}
          customProps={customProps}
        />
      ))}
    </>
  )
}
