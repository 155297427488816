import type { FunctionComponent } from 'react'
import 'twin.macro'
import { SimpleJson } from './SimpleJson'

export const SimpleError: FunctionComponent<React.PropsWithChildren<{
  msg: string
  json?: any
}>> = ({ msg, json }) => {
  return (
    <>
      <div tw="bg-red-200 p-4">
        <div tw="pb-3 text-lg font-extrabold">{msg}</div>
        {json && <SimpleJson value={json} />}
      </div>
    </>
  )
}
